// Bootstrap theme variables

:host,
:root {
  // bootstrap root variables that need to be redefined here so they can apply in a shadow dom
  // copied from https://getbootstrap.com/docs/5.2/customize/css-variables/
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13, 110, 253;
  --bs-secondary-rgb: 108, 117, 125;
  --bs-success-rgb: 25, 135, 84;
  --bs-info-rgb: 13, 202, 240;
  --bs-warning-rgb: 255, 193, 7;
  --bs-danger-rgb: 220, 53, 69;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-font-sans-serif: system-ui, -apple-system, 'Segoe UI', Roboto, 'Helvetica Neue', 'Noto Sans',
    'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New',
    monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-2xl: 2rem;
  --bs-border-radius-pill: 50rem;
  --bs-link-color: #0d6efd;
  --bs-link-hover-color: #0a58ca;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  // END BOOTSTRAP ROOT VARIABLES

  // Config response variables
  // These are used below to set our main app variables
  // Don't use these directly in the apps scss files, prefer using --vax and instead set the variable to one of these
  --theme-text-color: #161616;
  --theme-text-color-dark: #f6f6f6;
  --theme-background-color: #f5f8ff;
  --theme-background-color-dark: #080808;
  --theme-tint-color: #385d82;
  --theme-tint-color-primary-r: '56';
  --theme-tint-color-primary-g: '93';
  --theme-tint-color-primary-b: '130';
  --theme-tint-color-dark: #94a7bb;
  --theme-tint-color-primary-dark-r: '56';
  --theme-tint-color-primary-dark-g: '93';
  --theme-tint-color-primary-dark-b: '130';
  --theme-tint-active-color: #386da2;
  --theme-tint-active-color-dark: #a3b2c1;

  // App variables
  // General
  --vax-max-width: 988px; // max width of the container
  --vax-footer-height: 62px;
  --vax-appointment-search-header-height: 160px;
  // Buttons
  --vax-max-button-height: 62px;
  // Colors
  --vax-default-blue: #385d82;
  --vax-primary: var(--theme-tint-color);
  --vax-primary-r: var(--theme-tint-color-primary-r);
  --vax-primary-g: var(--theme-tint-color-primary-g);
  --vax-primary-b: var(--theme-tint-color-primary-b);
  --vax-primary-hover: var(--theme-tint-active-color);
  --vax-text-primary: var(--theme-text-color);
  --vax-text-secondary: rgba(82, 82, 82, 1);
  --vax-text-tertiary: rgb(115, 119, 125);
  --vax-medium-gray: rgb(115, 119, 125);
  --vax-medium-gray-border: rgba(115, 119, 125, 0.6);
  --vax-light-gray-border: rgba(224, 224, 224, 1);
  --vax-danger: #da3232;
  --vax-warning: #ed6101;
  --vax-success: #0da84f;
  --vax-warning-rgba: rgba(237, 97, 1, 0.1);
  --vax-input-placeholder: rgba(188, 190, 192, 1);
  --vax-badge-background: #ffffff;
  // Cards
  --vax-card-border-radius: 16px;
  --vax-card-background: #ffffff;
  // Typography
  --vax-headings-font: 'Poppins', sans-serif;
  --vax-font-family-sans-serif: 'Open Sans', sans-serif;
  --vax-font-size-root: 14px;
  // Modals
  --vax-modal-backdrop-bg: rgba(0, 0, 0, 0.38);
  --vax-modal-bg: rgb(255, 255, 255);
  --vax-modal-border-radius: 8px;
  --vax-modal-border: 1px solid #abadb1;
  // Skeleton Loading
  --vax-skeleton-gray-background: #8a8b8d;
  // Hero
  --vax-hero-step-opacity: 0.066;
  // Eligibility
  --vax-eligibility-background: #fafafc;
  --vax-eligibility-alert-background: #f2f2f2;

  // Bootstrap CSS variables
  // General
  --bs-body-bg: var(--theme-background-color) !important;
  --bs-font-sans-serif: var(--vax-font-family-sans-serif);
  // Cards
  --bs-card-bg: var(--vax-card-background);
  --bs-card-cap-bg: var(--vax-card-background);
  // Colors
  --bs-primary: var(--theme-tint-color);
  --bs-primary-rgb: rgb(var(--theme-tint-color));
  --bs-danger: var(--vax-danger);
  --bs-danger-rgb: 218, 50, 50;
  // Badge
  --bs-badge-color: var(--vax-medium-gray);
}

:host[data-bs-theme='dark'] {
  --vax-default-blue: #94a7bb;
  --vax-primary: var(--theme-tint-color-dark);
  --vax-primary-r: var(--theme-tint-color-primary-dark-r);
  --vax-primary-g: var(--theme-tint-color-primary-dark-g);
  --vax-primary-b: var(--theme-tint-color-primary-dark-b);
  --vax-primary-hover: var(--theme-tint-active-color-dark);
  --vax-text-primary: var(--theme-text-color-dark);
  --vax-text-secondary: rgba(194, 198, 203, 1);
  --vax-text-tertiary: rgba(146, 152, 161, 1);
  --vax-danger: #e02a2a;
  --vax-warning: #d25600;
  --vax-success: #179c4f;
  --vax-warning-rgba: rgba(210, 86, 0, 0.1);
  --vax-card-background: rgba(28, 28, 30, 1);
  --vax-modal-bg: #17171c;
  --vax-modal-border: #17171c;
  --vax-badge-background: rgba(28, 28, 30, 1);
  --vax-list-background: rgba(28, 28, 30, 1);
  --vax-light-gray-border: rgba(61, 63, 67, 1);
  --bs-body-bg: var(--theme-background-color-dark) !important;
  --vax-hero-step-opacity: 0.3;

  // Eligibility
  --vax-eligibility-background: #fafafc;
  --vax-eligibility-alert-background: #f2f2f2;
}

//
// Bootstrap SASS variable overrides
//
// General
$color-mode-type: data;
$enable-shadows: true;
$enable-dark-mode: false; // TODO: turning dark mode off since we don't have values configured

// Fonts
// Default font family
$font-family-sans-serif: 'Open Sans', sans-serif;
$headings-font-family: 'Poppins', sans-serif;

// Font sizing
$font-size-root: 14px; // default is 16px, but design p is 14px
$font-size-base: 1rem;
$h1-font-size: calc(1rem * (27px / $font-size-root));
$h2-font-size: calc(1rem * (20px / $font-size-root));
$h3-font-size: calc(1rem * (15px / $font-size-root));
$h4-font-size: calc(1rem * (16px / $font-size-root));
$h5-font-size: calc(1rem * (14px / $font-size-root));

// Badges
$badge-color: var(--bs-badge-color);
$badge-font-size: calc(1em * (14px / $font-size-root));
$badge-font-weight: 400;
$badge-padding-y: 0.2em;
$badge-padding-x: 13px;

// Buttons
// $btn-border-radius: 1.6rem;
$btn-font-family: $headings-font-family;
$btn-box-shadow: 0;
$btn-font-weight: 700;
$btn-border-radius: 8px;
$btn-disabled-opacity: 0.15;
$btn-white-space: break-spaces;
$btn-font-size: calc(1em * (16px / $font-size-root));
$btn-padding-y: 10.5px;
$btn-font-size-lg: calc(1em * (16px / $font-size-root));
$btn-padding-y-lg: 18px;
$btn-font-size-sm: calc(1em * (16px / $font-size-root));
$btn-padding-y-sm: 6px;

// Cards
$card-bg: var(--vax-card-background);
$card-box-shadow: none;
$card-border-radius: var(--vax-card-border-radius);
$card-border-width: 1px;
$card-cap-bg: var(--bs-card-cap-bg);
$card-cap-padding-y: 18px;

// Forms
$form-check-input-checked-bg-color: var(--vax-card-background);
$form-check-input-checked-color: currentColor;
$form-check-input-checked-border-color: var(--vax-medium-gray-border);
$form-check-radio-checked-bg-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none"><g id="radio-button"><circle id="Ellipse 48" cx="16" cy="16" r="16" fill="#fff"/><g id="fluent:checkmark-12-filled"><path id="Vector8" d="M22.2747 11.3413C22.5156 11.569 22.6561 11.883 22.6655 12.2143C22.6749 12.5455 22.5523 12.867 22.3247 13.1079L15.2414 20.6079C15.1266 20.7293 14.9887 20.8264 14.8357 20.8935C14.6827 20.9606 14.5179 20.9964 14.3509 20.9987C14.1838 21.0011 14.018 20.9699 13.8633 20.9071C13.7085 20.8442 13.5679 20.751 13.4497 20.6329L9.69974 16.8829C9.47894 16.646 9.35874 16.3326 9.36445 16.0087C9.37017 15.6849 9.50135 15.3759 9.73037 15.1469C9.9594 14.9179 10.2684 14.7867 10.5922 14.781C10.916 14.7753 11.2295 14.8955 11.4664 15.1163L14.3081 17.9563L20.5081 11.3913C20.7358 11.1504 21.0498 11.0099 21.3811 11.0005C21.7123 10.9911 22.0338 11.1137 22.2747 11.3413Z" fill="currentColor"/></g></g></svg>');
$form-check-min-height: 30px;
$form-check-input-width: calc(1em * (30px / $font-size-root));
$form-check-padding-start: $form-check-input-width + 1em;
$form-select-box-shadow: 0;
// $form-select-indicator-color: #000;
$form-select-focus-border-color: var(--vax-primary);
$form-switch-width: calc(1em * (51px / $font-size-root));

// Inputs
$input-box-shadow: 0;
$input-border-radius: 4px;
$input-border-color: var(--vax-medium-gray-border);
$input-focus-border-color: var(--vax-primary);
$input-padding-y: 11px;
$input-placeholder-color: var(--vax-input-placeholder);

// Placeholders (skeletons)
$placeholder-opacity-max: 0.15;
$placeholder-opacity-min: 0.1;
