/* You can add global styles to this file, and also import other style files */
@import './theme/custom.scss';

// Bootstrap overrides
:host,
html,
body {
  overscroll-behavior-y: none; // prevents the bounce on iOS safari and chrome
  font-size: var(--vax-font-size-root);
  font-family: var(--vax-font-family-sans-serif);
  background: transparent;
}

[data-bs-theme='dark'] {
  :host,
  html,
  body {
    overscroll-behavior-y: none; // prevents the bounce on iOS safari and chrome
    font-size: var(--vax-font-size-root);
    background: transparent;
  }
}

app-root {
  background: linear-gradient(180deg, var(--bs-body-bg) 0%, #fff 100%) !important;
  display: block;
}

app-root[data-bs-theme='dark'] {
  background: linear-gradient(180deg, var(--bs-body-bg) 0%, #000 100%) !important;
  display: block;
}

// Hide the recaptcha privacy badge and instead show in the user flow
// https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed
.grecaptcha-badge {
  visibility: hidden;
}

// Used for the address typeahead
typeahead-container {
  transform: translate3d(1px, 37px, 0px) !important;
  max-width: 93vw;
  top: 5px !important;
}

// prevents scrolling when our custom modals are presented
[data-modal-open='true'] {
  overflow: hidden;
}

.container {
  max-width: var(--vax-max-width);
  margin: 0 auto;
}

// Fixes browser autofill
input:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: var(--vax-card-background) !important;
  color: var(--vax-text-primary);
}

// Fixes browser autofill
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--vax-text-primary);
  -webkit-box-shadow: 0 0 0px 1000px var(--vax-card-background) inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: var(--vax-text-primary);
}

input:focus {
  touch-action: manipulation;
}

.form-control,
.form-select {
  font-size: 16px;
}

.form-check-input {
  --bs-form-check-bg: var(--vax-card-background);
  border-color: var(--vax-medium-gray-border);
  margin-top: 0;
}

.form-control,
.form-select {
  background-color: var(--vax-card-background);
}

// Alert - ngx-bootstrap
.alert-vaccine-recommendation {
  background-color: var(--vax-card-background);
  border-color: var(--vax-medium-gray-border);
  color: var(--vax-text-primary);
  padding: 12px 8px 12px 16px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Anchors
a {
  color: var(--vax-primary);
}

// Badge
.badge {
  border-width: 1px;
  border-style: solid;
  border-color: var(--vax-medium-gray-border);
  &.error {
    border-color: var(--vax-danger);
  }
  &.focused:not(.error) {
    border-color: var(--vax-primary);
  }
  background-color: var(--vax-badge-background);
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

// Buttons
.btn {
  max-width: 400px;
}

.btn-sm {
  padding: 6px 16px;
}

.btn-primary {
  --bs-btn-font-weight: 700;
  --bs-btn-bg: var(--vax-primary);
  --bs-btn-border-color: var(--vax-primary);
  --bs-btn-disabled-border-color: var(--vax-primary);
  --bs-btn-hover-bg: var(--vax-primary-hover);
  --bs-btn-active-bg: var(--vax-primary-hover);
  --bs-btn-active-border-color: var(--vax-primary-hover);
  --bs-btn-hover-border-color: var(--vax-primary-hover);
  --bs-btn-disabled-bg: var(--vax-primary);
}

.btn-outline-primary {
  --bs-btn-font-weight: 600;
  --bs-btn-bg: var(--vax-card-background);
  --bs-btn-border-color: var(--vax-primary);
  --bs-btn-color: var(--vax-primary);
  --bs-btn-disabled-color: var(--vax-primary);
  --bs-btn-disabled-border-color: var(--vax-primary);
  --bs-btn-hover-bg: var(--vax-primary-hover);
  --bs-btn-active-bg: var(--vax-primary-hover);
  --bs-btn-active-border-color: var(--vax-primary-hover);
  --bs-btn-hover-border-color: var(--vax-primary-hover);

  .icon {
    fill: var(--vax-primary);
  }

  &:hover .icon,
  &:focus .icon {
    fill: #fff;
  }
}

app-root[data-bs-theme='dark'] {
  .btn-primary {
    --bs-btn-disabled-border-color: #525a62;
    --bs-btn-disabled-bg: #525a62;
    --bs-btn-disabled-color: #9298a1;
    --bs-btn-disabled-opacity: 1;
  }

  .btn-outline-primary {
    --bs-btn-disabled-border-color: #525a62;
    --bs-btn-disabled-color: #9298a1;
    --bs-btn-disabled-opacity: 1;
  }
}

.btn-clear-primary {
  --bs-btn-font-weight: 700;
  --bs-btn-color: var(--vax-primary);
  --bs-btn-font-family: var(--vax-headings-font);

  &:focus,
  &:hover,
  &:active {
    --bs-btn-hover-color: var(--vax-primary-hover);
    --bs-btn-color: var(--vax-primary-hover);
    --bs-btn-active-color: var(--vax-primary-hover);
    --bs-btn-active-border-color: var(--vax-primary-hover);
  }

  &:focus-visible {
    border: 1px solid;
  }
}

.btn-link-primary {
  text-decoration: underline;
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--vax-primary);
  --bs-btn-font-family: var(--vax-font-family-sans-serif);

  &:focus,
  &:hover,
  &:active {
    --bs-btn-hover-color: var(--vax-primary-hover);
    --bs-btn-color: var(--vax-primary-hover);
    --bs-btn-active-color: var(--vax-primary-hover);
    --bs-btn-active-border-color: var(--vax-primary-hover);
  }
}

.btn-dashed {
  border-style: dashed;
}

// Colors
.primary {
  color: var(--vax-primary) !important;
}

.text-primary {
  color: var(--vax-text-primary) !important;
}

.text-secondary {
  color: var(--vax-text-secondary) !important;
}

.text-tertiary {
  color: var(--vax-text-tertiary);
}

// Typography
.text-normal {
  font-weight: 400;
}

.text-semibold {
  font-weight: 600;
}

.text-bold {
  font-weight: 700;
}

.text-small {
  font-size: 12px;
}

.text-medium {
  font-size: 14px;
}

.text-heading {
  font-family: var(--vax-headings-font);
}

.text-regular {
  font-family: var(--vax-font-family-sans-serif);
}

h1,
h5 {
  font-weight: 700;
}

h2,
h3,
h4 {
  font-weight: 600;
}

// Date Picker -- ngx-bootstrap
.custom-primary-color .bs-datepicker-container {
  background-color: var(--vax-card-background);
}

.custom-primary-color .bs-datepicker-head {
  background-color: var(--vax-primary);
}

.custom-primary-color .bs-datepicker-body {
  color: var(--vax-text-primary);
  border: var(--vax-card-background);

  table td {
    color: var(--vax-text-primary);

    // style the hover state for the date picker
    &.is-highlighted:not(.disabled):not(.selected) span,
    &.is-active-other-month:not(.disabled):not(.selected) span,
    span.is-active-other-month:not(.disabled):not(.selected),
    &:hover:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
      color: #fff;
      background-color: var(--vax-primary);
    }
  }
}

.custom-primary-color .bs-datepicker-body table td span.selected {
  background-color: var(--vax-primary) !important;
}

.custom-primary-color .bs-datepicker-container .bs-datepicker-navigation button {
  background-color: var(--vax-primary);
  border-color: var(--vax-primary);
}

.custom-primary-color .bs-datepicker-container .bs-datepicker-navigation button:hover {
  background-color: var(--vax-primary-hover);
  border-color: var(--vax-primary-hover);
}

// Images
.hero {
  border-radius: 8px;
}

// Divider
.divider {
  border-right: 1px solid var(--vax-text-tertiary);
  height: 18px;
  margin: 0 4px;
}

// Eligibility accordion
#eligibility-sections {
  .card-header {
    border-bottom: none;
  }
  .panel-body {
    padding-top: 0;
  }
}
